<template>
  <v-container class="fill-height">
    <v-row justify="center" align="center" align-content="center" class="fill-height">
      <v-col cols="12">
        <div class="display-3 text-center mb-8">
          {{ $t('paymentCanceled') }}
        </div>
        <div class="text-center display-1">
          {{ $t('paymentSorry') }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CanceledPage',
  components: {
    //
  },
  props: {
    //
  },
  async beforeMount() {
    await this.$store.dispatch('CheckAuthentication');
    this.auth = this.$store.state.authModule.isAuthenticated ? 1 : 2;
  },
  data() {
    return {
      auth: 0,
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
